var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", { staticClass: "text-brandPurple text-xl font-bold" }, [
        _vm._v("You will be redirected"),
      ]),
      _c("p", { staticClass: "mt-4 text-grey" }, [
        _vm._v(
          " You are about to visit " +
            _vm._s(_vm.merchantData.name) +
            " store website to shop and pay with Zilla. "
        ),
      ]),
      _c("Button", {
        staticClass: "mt-8",
        attrs: { text: "Yes, Proceed", width: "w-full" },
        on: {
          click: function ($event) {
            return _vm.redirectUser(_vm.merchantData.businessWebsite)
          },
        },
      }),
      _c("Button", {
        staticClass: "mt-4",
        attrs: { text: "No, Not interested", width: "w-full", outline: "" },
        on: {
          click: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }