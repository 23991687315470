<template>
  <div>
    <h4 class="text-brandPurple text-xl font-bold">You will be redirected</h4>
    <p class="mt-4 text-grey">
      You are about to visit {{ merchantData.name }} store website to shop and
      pay with Zilla.
    </p>
    <Button
      text="Yes, Proceed"
      width="w-full"
      class="mt-8"
      @click="redirectUser(merchantData.businessWebsite)"
    />
    <Button
      text="No, Not interested"
      width="w-full"
      outline
      class="mt-4"
      @click="$emit('close')"
    />
  </div>
</template>
<script>
  import { Button, SecondaryButton } from "@/UI/Button";
  export default {
    components: {
      Button,
      SecondaryButton,
    },
    props: {
      merchantData: {
        type: Object,
        default: () => {},
        required: true,
      },
    },
    methods: {
      redirectUser(link) {
        this.$emit("close");
        window.open(link, "_blank");
      },
    },
  };
</script>
